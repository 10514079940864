<!--
	This is the Orders List page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>

  <div>
    <div v-if="pageFlag === 'list'">
      <!-- Orders List header -->
      <!-- / Orders List header -->

      <!-- Orders List card -->
      <a-card :bordered="false" class="header-solid mb-24" :bodyStyle="{padding: 0, paddingTop: '16px'}">

        <!-- Table search -->
        <div class="mx-25">
          <a-row type="flex" :gutter="24">
            <a-col :span="12" class="text-left">
              <a-select
                  show-search
                  allowClear
                  placeholder="选择租户"
                  style="width: 200px;margin-right: 8px"
                  size="small"
                  v-model="query.tenantId"
                  :filter-option="filterOption"
                  @change="getData"
              >
                <a-select-option v-for="tenant in tenantList" :key="tenant.id" :value="tenant.id" >
                  {{ tenant.name }}
                </a-select-option>
              </a-select>
              <a-input-search placeholder="请输入查询信息" style="max-width: 200px;" v-model="query.name" @search="getData" size="small" />
            </a-col>
            <a-col :span="12" class="text-right">
              <a-button type="primary" size="small" @click="visible = true">{{ $t('add') }}</a-button>
            </a-col>
          </a-row>
        </div>
        <!-- / Table search -->

        <!-- Orders table -->
        <a-table class="mt-20"
                 :columns="columns"
                 :data-source="data"
                 rowKey="code"
                 :loading="loading"
                 :pagination="{pageSize: pageSize,current: pageNum,total:total}"
                 :scroll="{ x: true, y: true }"
                 @change="handleTableChange"
        >

          <template slot="avatar" slot-scope="avatar">
            <div style="height: 50px">
              <img :src="ossBaseUrl + avatar" style="height: 40px;max-width: 150px">
            </div>
          </template>
          <template slot="status" slot-scope="status">
            <span v-if="status" style="vertical-align: middle;font-size: 12px;color: #87d068">{{ $t('enable') }}</span>
            <span v-else style="vertical-align: middle;font-size: 12px;color: #f50">{{ $t('invalid') }}</span>
          </template>

          <template slot="operation" slot-scope="row">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="dash" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item key="1">
                  <span @click="edit(row)" style="cursor: pointer">
                    <a-icon type="edit" style="cursor: pointer"/> {{ $t('edit') }}
                  </span>
                </a-menu-item>
<!--                <a-menu-item key="2">-->
<!--                  <a-popconfirm :title="$t('delConfirmMsg')" :ok-text="$t('yes')" :cancel-text="$t('no')" @confirm="del(row.id)">-->
<!--                    <a-icon type="delete" style="cursor: pointer"/> {{ $t('delete') }}-->
<!--                  </a-popconfirm>-->
<!--                </a-menu-item>-->
                <a-menu-item>
                  <span style="cursor: pointer" @click="openModal(row)">
                    <a-icon type="snippets"/> {{ $t('user.bindTenant') }}
                  </span>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>

        </a-table>
        <!-- / Orders table -->

      </a-card>
      <a-drawer
          :title="drawerTitle"
          placement="right"
          :closable="true"
          :visible="visible"
          width="40%"
          :after-visible-change="afterVisibleChange"
          @close="onClose"
      >
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0 }" :loading="drawerLoading">
          <a-form-item class="mb-10" :label="$t('user.username')" :colon="false" required>
            <a-input v-model="form.username" size="small"/>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('user.nickname')" :colon="false">
            <a-input v-model="form.nickname" size="small"/>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('user.mobile')" :colon="false" required>
            <a-input v-model="form.mobile" size="small"/>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('user.email')" :colon="false" required>
            <a-input v-model="form.email" size="small"/>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('user.status')" :colon="false" >
            <a-select
                show-search
                allowClear
                option-filter-prop="children"
                style="width: 100%;margin-right: 8px"
                size="small"
                v-model="form.status"
                :filter-option="filterOption"
            >
              <a-select-option :value="true" >
                {{ $t('enable') }}
              </a-select-option>
              <a-select-option :value="false" >
                {{ $t('invalid') }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="mb-10" :label="$t('user.avatar')" :colon="false">
            <a-upload
                name="avatar"
                list-type="picture-card"
                class="avatar-uploader"
                :show-upload-list="false"
                :action="baseUrl + '/upload/avatar'"
                :before-upload="beforeUpload"
                :headers="uploadHeaders"
                accept="image/png, image/jpeg"
                @change="handleChange"
            >
              <img v-if="imageUrl" :src="ossBaseUrl + imageUrl" alt="logo" style="max-width: 200px;max-height: 200px"/>
              <div v-else>
                <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  {{ $t('upload') }}
                </div>
              </div>
            </a-upload>
          </a-form-item>
          <a-form-item style="text-align: right">
            <a-input v-model="form.id" size="small" type="hidden"/>
            <a-button type="primary" html-type="submit" @click="submit"  size="small">
              {{ $t('submit') }}
            </a-button>
          </a-form-item>
        </a-card>
      </a-drawer>
      <a-modal v-model="modalVisible" :title="$t('role.menu')" :loading="modalLoading"
               :dialog-style="{ top: '20px'}" :bodyStyle="{maxHeight: '70vh', overflowY: 'scroll'}" @cancel="handleCancel">
        <template slot="footer">
          <a-button key="back" @click="handleCancel">
            取消
          </a-button>
          <a-button key="submit" type="primary" :loading="loading" @click="submitUserRole">
            确认
          </a-button>
        </template>
        <a-form-item label="租户">
          <a-select
              mode="multiple"
              size="small"
              placeholder="Please select"
              :default-value="[]"
              style="width: 100%"
              @change="handleTenantChange"
              @popupScroll="popupScroll"
          >
            <a-select-option v-for="tenant in tenantList" :key="tenant.id">
              {{ tenant.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-collapse v-model="activeKey">
          <a-collapse-panel :key="item.id + ''" :header="item.name" v-for="item in selectTenantList">
            <p>
              <a-checkbox-group @change="onChange($event, item)" :defaultValue="checkedValues[item.code]">
                <a-row>
                  <a-col :span="24" v-for="role in item.roles" :key="role.id">
                    <a-checkbox :value="role.id">
                      {{ role.name }}
                    </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </p>
          </a-collapse-panel>
        </a-collapse>
      </a-modal>

    </div>
  </div>

</template>

<script>

import {constructMenuTree, extractMenuCode, extractMenuCodeAndIdRef, rebuildMenus} from "../../utils/common";
import {getToken} from "../../utils/auth";

export default {
  data() {
    return {
      postFlag: true,
      loading: false,
      uploadLoading: false,
      drawerLoading: false,
      modalLoading: false,
      activeKey: [],
      visible: false,
      dropVisible: false,
      modalVisible: false,
      drawerTitle: this.$t('add'),
      pageFlag: 'list',
      imageUrl: '',
      // Table columns
      columns: [
        {
          title: this.$i18n.t('user.avatar'),
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar' },
          width: 150
        },
        {
          title: this.$i18n.t('user.nickname'),
          dataIndex: 'nickname',
          // scopedSlots: { customRender: 'code' },
          width: 120
        },
        {
          title: this.$i18n.t('user.username'),
          dataIndex: 'username',
          width: 120
        },
        {
          title: this.$i18n.t('user.mobile'),
          dataIndex: 'mobile',
          width: 150
        },
        {
          title: this.$i18n.t('user.email'),
          dataIndex: 'email',
          // scopedSlots: { customRender: 'status' },
          width: 200
        },
        {
          title: this.$i18n.t('user.status'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 100
        },
        {
          title: this.$i18n.t('lastModifiedDate'),
          dataIndex: 'lastModifiedDate',
          width: 180
        },
        {
          title: this.$i18n.t('operation'),
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
          width: 120
        },
      ],

      // Table rows
      data: [],

      // Table page size
      pageSize: 10,
      pageNum: 1,
      total: 0,

      // Table search query
      query: {
        name: null,
        tenantId: undefined
      },

      // Table's selected rows
      selectedRowKeys: [],
      selectTenantList: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        id: null,
        avatar: null,
        username: null,
        nickname: null,
        mobile: null,
        email: null,
        lastModifiedDate: null,
        status: true
      },
      tenantList: [],
      roleTypeList: [],
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      treeData: [],
      uploadHeaders: {
        Authorization: 'Bearer ' + getToken()
      },
      ossBaseUrl: process.env.VUE_APP_OSS_BASE_URL,
      baseUrl: process.env.VUE_APP_BASE_API,
      checkedValues: {}
    }
  },
  mounted() {
    this.getTenantList()
    this.getData()
  },
  watch: {
    activeKey(key) {
      console.log(key);
    },
  },
  methods: {
    openModal(row) {
      this.modalVisible = true
      this.form = row
      this.$store.dispatch('GET_USER_ROLES', {userId: row.id}).then(res => {
        console.log('GET_USER_ROLES', res)
        if (res.errorCode === '0') {
          this.checkedValues = res.data.userRoleIds
          res.data.tenantIds.forEach(x => {
            this.tenantList.forEach(t => {
              if (x === t.id) {
                this.$store.dispatch('ROLE_LIST', {params:{pageSize:500, pageNum:1}, data: {tenantId: t.id}}).then(res => {
                  if (res.errorCode === '0'){
                    t.roles = res.data.list
                    this.selectTenantList.push(t)
                    this.activeKey.push(x + '')
                  }
                })
              }
            })
          })
        }
      })
    },
    getMenuTree() {
      let codes = []
      let ref = {}
      this.$store.dispatch('GET_MENU_TREE').then(res => {
        if(res.errorCode === '0') {
          codes = extractMenuCode(res.data, codes)
          ref = extractMenuCodeAndIdRef(res.data, ref)
          this.$store.commit('SET_MENU_CODES', codes)
          const routes = rebuildMenus(this.$router.options.routes, codes, false)
          this.treeData = constructMenuTree(routes, ref, this.$i18n)
          this.modalVisible = true
        }
      })
    },
    getMenuChecked(roleId) {
      const params = {
        roleId: roleId
      }
      this.$store.dispatch('MENU_CHECKED', params).then(res => {
        if(res.errorCode === '0') {
          this.expandedKeys = res.data
          this.checkedKeys = res.data
        }
      })
    },
    getTenantList() {
      this.$store.dispatch('TENANT_LIST', {params: {pageNum: 1,pageSize: 9999}, data: {}}).then(res => {
        if (res.errorCode === '0') {
          this.tenantList = res.data.list
        }
      })
    },
    getRoleTypeList(tenantId) {
      const params = {
        pageNum: 1,
        pageSize: 9999,
        tenantId: tenantId,
        typeCode: 'ROLE_TYPE'
      }
      this.$store.dispatch('PARAM_LIST_BY_TYPE_CODE', params).then(res => {
        if (res.errorCode === '0') {
          this.roleTypeList = res.data.parameterDTOS
        }
      })
    },
    afterVisibleChange() {},
    onSubmit() {},
    onClose() {
      this.visible = false
      this.resetForm()
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // Event listener for input change on table search field.
    onSearch() {
      this.getData()
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
    },
    // Event listener for table row selection change.
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true;
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        if (info.file.response.errorCode === '0') {
          this.imageUrl = info.file.response.data
        } else {
          this.$message.error(info.file.response.message)
        }
        this.uploadLoading = false;
        // this.getBase64(info.file.originFileObj, imageUrl => {
        //   this.imageUrl = imageUrl;
        //   this.drawerLoading = false;
        // });
      }
    },
    // Export table in CSV format.
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join("|"),
        ...arrData.map(item => Object.values(item).join("|"))
      ]
          .join("\n")
          .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "muse-dashboard-csv.csv");
      link.click();
    },
    getData() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      const searchForm = {
        name: this.query.name,
        tenantId: this.query.tenantId
      }

      this.$store.dispatch('USER_LIST', {params: params, data: searchForm}).then(res => {
        if (res.errorCode === '0') {
          this.data = res.data.list
          this.total = res.data.total
        } else {
          this.$message.error(res.message)
        }
      })
    },
    submit() {
      this.drawerLoading = true
      if (this.postFlag) {
        this.postFlag = false
        this.form.avatar = this.imageUrl
        this.$store.dispatch("SAVE_USER", this.form).then(res => {
          this.drawerLoading = false
          this.postFlag = true
          if (res.errorCode === '0') {
            this.visible = false
            this.resetForm()
            this.$message.success(res.message, 1, this.getData)
          } else {
            this.$message.error(res.message)
          }
        }).catch(err => {
          this.drawerLoading = false
          this.postFlag = true
          this.$message.error(err)
        })

      }
    },
    resetForm() {
      this.form = {
        id: null,
        avatar: null,
        username: null,
        nickname: null,
        mobile: null,
        email: null,
        lastModifiedDate: null,
        status: true
      }
      this.imageUrl = null
      this.drawerTitle = this.$t('add')
    },
    edit(row) {
      this.drawerTitle = this.$t('edit')
      this.getRoleTypeList()
      this.form = row
      this.imageUrl = this.form.avatar
      this.visible = true
    },
    handleTableChange(pagination) {
      this.pageNum = pagination.current
      this.total = pagination.total
      this.getData()
    },
    del(id) {
      const param = {
        id: id
      }
      if (this.postFlag) {
        this.postFlag = false
        this.loading = true
        this.$store.dispatch('DEL_ROLE', param).then(res => {
          this.postFlag = true
          if (res.errorCode === '0') {
            this.$message.success(res.message, 1, this.getData)
          } else {
            this.$message.error(res.message)
          }
          this.loading = false
        }).catch(err => {
          this.postFlag = true
          this.loading = false
          this.$message.error(err)
        })
      }
    },
    onExpand(expandedKeys) {
      console.log('onExpand', expandedKeys);
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys) {
      console.log('onCheck', checkedKeys);
      this.checkedKeys = checkedKeys;
    },
    onSelect(selectedKeys, info) {
      console.log('onSelect', info);
      this.selectedKeys = selectedKeys;
    },
    handleCancel() {
      this.modalVisible = false
      this.resetForm()
    },
    submitUserRole() {
      const params = {
        roleId: this.form.id
      }
      if (this.postFlag) {
        this.postFlag = false
        this.modalLoading = true
        this.$store.dispatch('SAVE_USER_ROLES', {params: { userId: this.form.id}, data: this.checkedValues}).then(res => {

          this.modalLoading = false
          this.postFlag = true
          if (res.errorCode === '0') {
            this.modalVisible = false
            this.resetForm()
            this.$message.success(res.message, 1)
          } else {
            this.$message.error(res.message)
          }
        }).catch(err => {
          this.postFlag = true
          this.modalLoading = false
          this.$message.error(err)
        })
      }
    },
    handleTenantChange(val) {
      this.selectTenantList = []
      this.activeKey = []
      val.forEach(x => {
        this.tenantList.forEach(t => {
          if (x === t.id) {
            this.$store.dispatch('ROLE_LIST', {params:{pageSize:500, pageNum:1}, data: {tenantId: t.id}}).then(res => {
              if (res.errorCode === '0'){
                t.roles = res.data.list
                this.selectTenantList.push(t)
                this.activeKey.push(x + '')
              }
            })
          }
        })
      })
    },
    popupScroll(val) {},
    onChange(checkedValues, tenant) {
      this.checkedValues[tenant.code] = checkedValues
    },
  },
}

</script>

<style lang="scss" scoped>
.table-avatar-info {
  display: flex;
  align-items: center;
}
.table-avatar-info .ant-avatar {
  margin-right: 8px;
}

// Using vuejs "Deep Selectors"
.table-avatar-info::v-deep .ant-avatar-string {
  font-size: 12px;
}
.btn-status::v-deep .anticon {
  line-height: 0;
}
.ant-select::v-deep .ant-select-selection__placeholder {
  font-weight: normal;
}
.ant-input-search::v-deep input::placeholder {
  font-weight: normal;
}
.ant-modal-content::v-deep .ant-modal-content {
  max-height: 450px;
  overflow-y: scroll;
}
</style>